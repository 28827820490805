<template>
  <div>
    <!-- Cabeçalho -->
    <div
      class="
        mb-2
        text-center
        d-flex
        flex-row
        justify-content-between
        align-items-center
      "
    >
      <h5 class="font-weight-bold m-0">{{ title }}</h5>
      <feather-icon :icon="icon" size="25" />
    </div>

    <!-- Pesquisa -->
    <b-input-group v-if="searchAction">
      <b-form-input
        placeholder="Digite o nome..."
        autocomplete="off"
        v-model="search"
        @keyup.enter="findList"
      />
      <b-input-group-append>
        <b-button @click="findList" variant="outline-primary">
          <feather-icon icon="SearchIcon" />
        </b-button>
      </b-input-group-append>
    </b-input-group>

    <b-input-group class="input-group-merge" v-else>
      <b-input-group-prepend is-text>
        <feather-icon icon="SearchIcon" />
      </b-input-group-prepend>
      <b-form-input
        placeholder="Procurar..."
        autocomplete="off"
        v-model="search"
        @keyup="filter"
      />
    </b-input-group>

    <!-- Draggable -->
    <b-overlay
      :show="overlay"
      :variant="skin"
      opacity="1"
      rounded="sm"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
    >
      <draggable
        :id="draggableID"
        :list="pagination[InCurrentePage - 1]"
        tag="ul"
        group="people"
        class="mt-2 list-group list-group-flush cursor-move"
        @change="changeList"
        :style="{ minHeight: Math.max(height, pHeight) + 'px' }"
      >
        <b-list-group-item
          v-for="(listItem, index) in pagination[InCurrentePage - 1]"
          :key="index"
          tag="li"
          class="my-25"
          style="border-width: 1px; border-radius: 3px"
        >
          <div class="d-flex">
            <div class="ml-25" style="font-size: 10pt">
              <b-card-text class="m-0 p-0">
                {{ listItem.label }}
              </b-card-text>
              <small class="m-0 p-0" v-if="listItem.subtitle">{{
                listItem.subtitle
              }}</small>
            </div>
          </div>
        </b-list-group-item>
      </draggable>
    </b-overlay>
    <b-pagination
      v-model="InCurrentePage"
      :total-rows="inRows"
      :per-page="perPage"
      align="center"
      first-number
      last-number
      limit="4"
      class="mt-2"
    >
      <template #prev-text>
        <feather-icon icon="ChevronLeftIcon" size="18" />
      </template>
      <template #next-text>
        <feather-icon icon="ChevronRightIcon" size="18" />
      </template>
    </b-pagination>
  </div>
</template>

<script>
import useAppConfig from "@core/app-config/useAppConfig";
import draggable from "vuedraggable";
import {
  BListGroupItem,
  BInputGroup,
  BFormGroup,
  BFormInput,
  BInputGroupPrepend,
  BInputGroupAppend,
  BPagination,
  BOverlay,
} from "bootstrap-vue";

export default {
  setup() {
    const { skin } = useAppConfig();
    return {
      skin,
    };
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    icon: {
      type: String,
      default: "ArrowDownCircleIcon",
    },
    list: {
      type: Array,
      required: true,
    },
    perPage: {
      type: Number,
      default: 5,
    },
    height: {
      type: Number,
      default: 0,
    },
    searchAction: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    draggable,
    BPagination,
    BListGroupItem,
    BInputGroup,
    BFormGroup,
    BFormInput,
    BInputGroupPrepend,
    BInputGroupAppend,
    BPagination,
    BOverlay,
  },
  computed: {
    inRows() {
      return this.list.length;
    },
  },
  data() {
    return {
      search: "",
      InCurrentePage: 1,
      pagination: [[]],
      fakePagination: [[]],
      listAux: [],
      pHeight: null,
      overlay: false,
      draggableID: "",
    };
  },
  watch: {
    list() {
      this.mountePagination();
      if (!this.overlay && this.search && !this.searchAction) {
        this.overlay = true;
        setTimeout(() => {
          this.filter();
          setTimeout(() => {
            this.overlay = false;
          }, 200);
        }, 200);
      }
    },
  },
  mounted() {
    this.draggableID = Math.floor(Math.random() * (1 - 100 + 1) + 1).toString();
    this.mountePagination();
    this.$nextTick(() => {
      this.getHeight();
    });
  },
  methods: {
    getHeight() {
      this.pHeight = document.getElementById(this.draggableID).clientHeight;
      this.$emit("greatestHeight", this.pHeight);
    },
    mountePagination() {
      this.pagination = [[]];
      this.list.forEach((p) => {
        if (this.pagination[this.pagination.length - 1].length < this.perPage) {
          this.pagination[this.pagination.length - 1].push(p);
        } else {
          this.pagination.push([p]);
        }
      });
      if (!this.search || this.searchAction) {
        this.listAux = this.list.map((m) => m);
      }
    },
    filter() {
      if (this.search) {
        this.list = this.listAux.filter((f) =>
          f.label.toLowerCase().includes(this.search.toLowerCase())
        );
      } else {
        this.list = this.listAux;
      }
    },
    changeList(_params) {
      if (_params.added) {
        this.listAux.splice(_params.added.newIndex, 0, _params.added.element);
      } else if (_params.removed) {
        let index = this.listAux.findIndex((item) => {
          return item.value === _params.removed.element.value;
        });
        this.listAux.splice(index, 1);
      }

      this.$emit("change", this.listAux);
    },
    findList() {
      this.$emit("findlist", this.search);
    },
  },
};
</script>

<style scoped>
</style>