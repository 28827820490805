<template>
  <viewcard--c
    :title="$route.params.id > 0 ? 'Atualizar Empresa' : 'Cadastrar Empresa'"
    :btsave="$route.params.id > 0 ? btedit : btcreate"
    :btback="{}"
    :busy="loading"
    @clicked-save="validationForm"
  >
    <hr class="p-0 m-0 mb-1" />
    <validation-observer ref="companyRules">
      <b-form @submit.prevent>
        <b-tabs pills>
          <b-tab active title="Dados Básicos">
            <b-row class="pt-2">
              <b-col md="6">
                <b-form-group label="Nome *">
                  <validation-provider
                    #default="{ errors }"
                    name="Nome da empresa"
                    rules="required"
                  >
                    <b-form-input
                      v-model="record.name"
                      placeholder="Nome da empresa"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="E-mail Departamento Produto *">
                  <validation-provider
                    #default="{ errors }"
                    name="E-mail Departamento Produto"
                    rules="required|email"
                  >
                    <b-form-input
                      type="email"
                      name="email_department"
                      v-model="record.emaildepartmentproduct"
                      placeholder="Informe um e-mail"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="E-mail Departamento Marketing *">
                  <validation-provider
                    #default="{ errors }"
                    name="E-mail Departamento Marketing"
                    rules="required|email"
                  >
                    <div class="form-label-group">
                      <b-form-input
                        type="email"
                        name="email_marketing"
                        v-model="record.emailmarketing"
                        placeholder="Informe um e-mail"
                      />
                    </div>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="E-mail do Google Calendar *">
                  <validation-provider
                    #default="{ errors }"
                    name="E-mail do Google Calendar"
                    rules="required|email"
                  >
                    <div class="form-label-group">
                      <b-form-input
                        id="email_marketing"
                        v-model="record.googlecalendar"
                        placeholder="Informe um e-mail"
                        name="email_calendar"
                      />
                    </div>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="3" md="2">
                <b-form-group label="Cor Padrão *">
                  <validation-provider
                    #default="{ errors }"
                    name="Cor Padrão"
                    rules="required"
                  >
                    <b-form-input v-model="record.color" type="color" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="3" md="2">
                <b-form-group label="Eventos Simultâneos *">
                  <validation-provider
                    #default="{ errors }"
                    name="Eventos Simultâneos"
                    rules="required"
                  >
                    <b-form-input
                      v-model="record.amountconcurrentevents"
                      type="number"
                      placeholder="Informe a quantidade"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </b-tab>
          <!-- YOUTUBE -->
          <b-tab title="YouTube">
            <b-row>
              <b-col lg="auto">
                <b-col>
                  <b-form-group label="Youtube Thumbnail">
                    <FileUpload
                      :image="urlImage"
                      width="400"
                      @fileChange="onFileChange"
                    />
                  </b-form-group>
                </b-col>
              </b-col>
              <b-col>
                <b-col md="auto">
                  <b-form-group label="Link automatico">
                    <span class="font-weight-light">
                      <b-form-checkbox
                        class="custom-control-success mt-1"
                        name="check-button"
                        switch
                        v-model="record.enable_youtube_api"
                      >
                        <span class="switch-icon-left">
                          <feather-icon icon="CheckIcon" />
                        </span>
                        <span class="switch-icon-right">
                          <feather-icon icon="XIcon" />
                        </span>
                      </b-form-checkbox>
                    </span>
                  </b-form-group>
                </b-col>
                <b-col md="auto">
                  <b-form-group label="Status de criação do link">
                    <span class="font-weight-light">
                      <v-select
                        v-model="companyVisibility"
                        :options="companyVisibilityOptions"
                        :clearable="false"
                      />
                    </span>
                  </b-form-group>
                </b-col>
                <b-col md="auto">
                  <b-form-group label="Status do link de transmissão">
                    <span class="font-weight-light">
                      <v-select
                        v-model="statusYoutubeTransmission"
                        :options="statusYoutubeTransmissionOptions"
                        :clearable="false"
                      />
                    </span>
                  </b-form-group>
                </b-col>
                <b-col md="auto">
                  <b-form-group label="Tempo troca status youtube">
                    <div class="form-label-group">
                      <flat-pickr
                        v-model="record.time_to_change_youtube_status"
                        placeholder="Duração"
                        class="form-control"
                        :config="{
                          enableTime: true,
                          noCalendar: true,
                          dateFormat: 'H:i',
                          time_24hr: true,
                        }"
                      />
                    </div>
                  </b-form-group>
                </b-col>
                <!--
                <b-form-group label="Youtube Link">
                  <div class="form-label-group">
                    <b-form-input
                      v-model="record.youtubelink"
                      placeholder="Informe o link do youtube"
                    />
                  </div>
                </b-form-group>
              </b-col>-->
              </b-col>
              <b-col cols="12">
                <b-form-group class="p-1" label="Youtube Descrição">
                  <div class="form-label-group">
                    <b-form-textarea
                      rows="5"
                      v-model="record.youtubedescription"
                      placeholder="Informe uma breve descrição (opcional)"
                    />
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
          </b-tab>
          <!-- USUARIOS -->
          <b-tab @click="ready = true" title="Usuários">
            <b-row>
              <b-col md="6">
                <Draggable
                  v-if="ready"
                  title="Usuários Incluídos"
                  icon="ArrowDownCircleIcon"
                  :perPage="7"
                  :list="record.users"
                  :height="draggableHeight"
                  @change="recordChange"
                  @greatestHeight="getDraggableHeight"
                />
              </b-col>
              <b-col md="6">
                <Draggable
                  v-if="ready"
                  title="Usuários Disponíveis"
                  icon="ArrowDownCircleIcon"
                  :searchAction="true"
                  :perPage="7"
                  :list="users"
                  :height="draggableHeight"
                  @greatestHeight="getDraggableHeight"
                  @findlist="filterAvails"
                  @change="UsersChange"
                />
              </b-col>
            </b-row>
          </b-tab>
          <b-tab title="Produtos">
            <b-row>
              <b-col cols="3" md="3" class="mt-1">
                <b-form-group label="Tipos de Produto">
                  <v-select
                    v-if="products.length > 0"
                    v-model="productObject.productSelected"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="products"
                    autocomplete="off"
                    :clearable="false"
                    size="lg"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="2" md="2" class="mt-1">
                <b-form-group label="Id da Plataforma">
                  <b-form-input v-model="productObject.id_platform" />
                </b-form-group>
              </b-col>
              <b-col cols="1" md="1" class="mt-3">
                <b-button
                  variant="info"
                  @click="addListProduct()"
                  :id="`adicionar-produto`"
                >
                  <feather-icon icon="PlusIcon" />
                  <b-tooltip
                    triggers="hover"
                    :target="`adicionar-produto`"
                    title="Adicionar Produto"
                    :delay="{ show: 100, hide: 50 }"
                  />
                </b-button>
              </b-col>
            </b-row>
            <b-row v-if="itens_tabela.length > 0">
              <b-col cols="6" md="6">
                <b-table
                  :items="itens_tabela"
                  :fields="fields"
                  :busy="loading"
                  responsive
                  hover
                  class="mt-1"
                >
                  <template #cell(actions)="data">
                    <div class="text-nowrap">
                      <feather-icon
                        @click="removeList(data.item)"
                        icon="TrashIcon"
                        class="mx-1"
                        size="22"
                      />
                    </div>
                  </template>
                </b-table>
              </b-col>
            </b-row>
          </b-tab>
        </b-tabs>
      </b-form>
    </validation-observer>
  </viewcard--c>
</template>
<script>
import _companyService from "@/services/company-service";
import _usersService from "@/services/users-service";
import { required } from "@validations";
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import pt_br from "vee-validate/dist/locale/pt_BR.json";
import FileUpload from "@/components/FileUpload.vue";
import Draggable from "@/components/Draggable.vue";
import { BListGroupItem } from "bootstrap-vue";
import _productsService from "@/services/audiovisual/products-service";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    Draggable,
    BListGroupItem,
    FileUpload,
  },
  data() {
    return {
      btedit: { permission: `permission.company.edit` },
      btcreate: { permission: `permission.company.create` },
      btdelete: { permission: `permission.company.delete` },
      loading: false,
      users: [],
      record: {
        id: 0,
        name: "",
        amountconcurrentevents: null,
        googlecalendar: "",
        youtubeimage: "",
        youtubedescription: "",
        youtubelink: "",
        emaildepartmentproduct: "",
        color: "#000000",
        company_uptos3_id: null,
        users: [],
        time_to_change_youtube_status: "",
        companyProductPlatforms: [],
      },
      companyVisibility: { label: "Público", value: "0" },
      companyVisibilityOptions: [
        { label: "Público", value: "0" },
        { label: "Privado", value: "1" },
        { label: "Não Listado", value: "2" },
      ],
      statusYoutubeTransmission: { label: "Público", value: "0" },
      statusYoutubeTransmissionOptions: [
        { label: "Público", value: "0" },
        { label: "Não Listado", value: "2" },
      ],
      selectedFile: null,
      urlImage: null,
      ready: false,
      draggableHeight: 0,
      productObject: {
        productSelected: null,
        id_platform: "",
      },
      products: [],
      itens_tabela: [],
      fields: [
        { key: "label", label: "Produto" },
        { key: "id_platform", label: "Id Plataforma" },
        { key: "actions", label: "Ações" },
      ],
    };
  },
  async created() {
    localize("pt_BR", pt_br);
    this.loading = true;
    let products = (await _productsService.showProductsAll()).content;
    this.products = this.$utils.populardropdown(products, "name", "id", false, false);
    this.getRecord();
  },
  methods: {
    getDraggableHeight(_height) {
      if (_height > this.draggableHeight) {
        this.draggableHeight = _height;
      }
    },
    validationForm() {
      this.$refs.companyRules.validate().then((success) => {
        if (success) {
          this.save();
        }
      });
    },
    getRecord() {
      if (this.$route.params.id > 0) {
        _companyService
          .find(this.$route.params.id)
          .then((res) => {
            this.record = res.content;
            if (this.record.youtubeimage) {
              this.urlImage = `${res.pathimage}/${this.record.youtubeimage}`;
            }
            this.record.users = res.content.companyUsers.map((m) => {
              return {
                label: m.user.name,
                subtitle: m.user.email,
                value: m.user.id,
              };
            });

            this.companyVisibility =
              this.record.default_visibility === 0
                ? this.companyVisibilityOptions[0]
                : this.record.default_visibility === 1
                ? this.companyVisibilityOptions[1]
                : this.companyVisibilityOptions[2];

            this.statusYoutubeTransmission =
              this.record.youtube_visibility_before_transmission === 0
                ? this.statusYoutubeTransmissionOptions[0]
                : this.statusYoutubeTransmissionOptions[1];

            //Produtos
            if (
              this.record.companyProductPlatforms &&
              this.record.companyProductPlatforms.length > 0
            ) {
              this.itens_tabela = [];
              this.itens_tabela.push(
                ...this.record.companyProductPlatforms.map((m) => {
                  const product_selected = this.products.filter(
                    (f) => f.value === m.product_id
                  );
                  if (product_selected.length > 0) {
                    return {
                      product: m.product_id,
                      label: product_selected[0].label,
                      id_platform: m.platform_id,
                    };
                  }
                })
              );
            }
          })
          .catch((error) => this.$utils.toastError("Notificação", error))
          .finally(() => {
            this.loading = false;
          });
      }
    },
    save() {
      this.record.companyUsers = this.record.users.map((m) => {
        return {
          user_id: m.value,
        };
      });

      let payload = new FormData();

      this.record.default_visibility = this.companyVisibility.value;

      this.record.youtube_visibility_before_transmission =
        this.statusYoutubeTransmission.value;

      this.record.companyProductPlatforms = [];
      if (this.itens_tabela) {
        this.itens_tabela.forEach((element) => {
          this.record.companyProductPlatforms.push({
            product_id: element.product,
            platform_id: element.id_platform,
          });
        });
      }

      if (this.selectedFile) {
        payload.append("capa", this.selectedFile);
      }
      payload.append("dados", JSON.stringify(this.record));

      //promisse
      const _createOrUpdate =
        this.record.id <= 0
          ? _companyService.create(payload)
          : _companyService.update(payload);

      this.loading = true;
      _createOrUpdate
        .then(() => {
          this.$utils.toast("Notificação", "Registro salvo com sucesso.");
          this.$router.go(-1);
        })
        .catch((error) => this.$utils.toastError("Notificação", error))
        .finally(() => (this.loading = false));
    },
    filterAvails(_searchTxt) {
      this.loading = true;
      _usersService
        .show(1, _searchTxt)
        .then((res) => {
          if (res.content) {
            this.users = res.content.map((m) => {
              return {
                label: m.name,
                subtitle: m.email,
                value: m.id,
              };
            });
          }
        })
        .catch((error) => this.$utils.toastError("Notificação", error))
        .finally(() => {
          this.loading = false;
        });
    },
    onFileChange(file) {
      this.selectedFile = file;
    },
    recordChange(payload) {
      this.record.users = payload;
    },
    UsersChange(payload) {
      this.users = payload;
    },
    addListProduct() {
      debugger;
      if (this.productObject.productSelected) {
        let limpar = false;
        let product_id = this.$utils.getValueSelected(
          this.productObject.productSelected
        );

        const product_selected = this.itens_tabela.filter(
          (f) => f.product === product_id
        );
        if (product_selected.length > 0) {
          limpar = true;
          this.$utils.toastError("Notificação", "Produto já adicionado.");
        } else {
          limpar = true;
          this.itens_tabela.push({
            product: product_id,
            label: this.productObject.productSelected.label,
            id_platform: this.productObject.id_platform,
          });
        }
        if (limpar) {
          this.productObject.id_platform = "";
          this.productObject.productSelected = null;
        }
      }
    },
    removeList(item) {
      const new_list = this.itens_tabela.filter(
        (f) => f.product !== item.product
      );
      this.itens_tabela = new_list;
    },
  },
};
</script>
<style>
#thumbnail-youtube {
  border: 1px solid #999;
  width: 100px;
  height: 70px;
  border-radius: 10%;
}

#thumbnail-youtube:hover {
  opacity: 0.5;
}
</style>